<template>
    <div class="authorization-page">

        <div class="form">
            <router-link :to="{name: 'Home'}">
                <img :src="require('@/assets/img/logo.png')" class="logo" alt="EURECBROKER">
            </router-link>

            <h1>{{ $t("Loading..") }}</h1>


        </div>
        <div class="side">

        </div>
    </div>
</template>

<script>
import {userService} from "@/services/userService";

export default {
    name: "SocialAuthorizationCallback",
    data() {
        return {

        }
    },
    mounted() {
        this.socialAuthCallback(this.$route.params.network, this.$route.query)
    },
    emits: ['hideLoginForm'],
    methods: {
        socialAuthCallback(network, params) {
            userService.socialAuthCallback(network, params)
            .then(response => {
                this.$store.dispatch('setToken', response.data.access_token)
                this.$router.push({ name: 'Home' })
            })
            .catch(error => {

            }).finally(() => this.disabled = false)

        }
    },
}
</script>

<style scoped>

</style>